import React, { useMemo, useState, useRef } from "react";
import styles from "./styles.module.sass";
import "./styles.less";
import { Dropdown } from "antd";
import images from "../../../assets/images/images";
import { Controller } from "react-hook-form";
import { IMultiSelectOverlayProps, IMultiSelectProps } from "../types";

const Overlay = <T extends string | number>({
  options,
  onSelect,
  selectedItem,
  allText,
}: IMultiSelectOverlayProps<T>) => {
  const allCheckedIcon = useMemo(
    () => (
      <div
        className={`${
          options.length === selectedItem.length || !!selectedItem.length
            ? styles.selectedItemWrapper
            : styles.checkboxWrapper
        }`}
      >
        {!!selectedItem.length && (
          <img
            src={
              options.length === selectedItem.length
                ? images.whitecheckicon
                : images.minusIcon
            }
          />
        )}
      </div>
    ),
    [options, selectedItem]
  );
  return (
    <React.Fragment>
      <div
        className={styles.itemWrapper}
        onClick={() => {
          onSelect({
            label: allText ?? "All",
            value: "*" as any,
          });
        }}
      >
        {allCheckedIcon}
        <span className={styles.itemLabel}>{allText ?? "All"}</span>
      </div>
      {options.map((item, index) => (
        <div
          className={styles.itemWrapper}
          key={index}
          onClick={() => {
            onSelect({
              label: item.label,
              value: item.value,
            });
          }}
        >
          <div
            className={`${
              selectedItem.includes(item.value)
                ? styles.selectedItemWrapper
                : styles.checkboxWrapper
            }`}
          >
            {selectedItem.includes(item.value) && (
              <img src={images.whitecheckicon} />
            )}
          </div>
          <span className={styles.itemLabel}>{item.label}</span>
        </div>
      ))}
    </React.Fragment>
  );
};

const MultiSelect = <T extends string | number>({
  options,
  label,
  placeholder,
  allText,
  placement,
  overlayClassName,
  dropdownClassName,
  control,
  name,
  onSelect,
  value,
  hideError,
  disabled,
}: IMultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedOptionLabel = options.find((option) => {
    if (value.length === 1) {
      return value.includes(option.value);
    }
  })?.label;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => {
        return (
          <Dropdown
            disabled={disabled}
            onOpenChange={(value) => {
              setIsOpen(value);
            }}
            overlay={
              <Overlay
                selectedItem={value as T[]}
                options={options}
                allText={allText}
                onSelect={(item) => {
                  if (value.includes(item.value)) {
                    onChange(value.filter((v) => v !== item.value));
                  } else if (item.value === "*") {
                    onChange(
                      !!value.length && value.length === options.length
                        ? []
                        : options.map((option) => option.value)
                    );
                  } else {
                    onChange(value.concat(item.value));
                  }
                  if (onSelect) {
                    onSelect(item);
                  }
                }}
              />
            }
            align={{ offset: label ? [60, 4] : [0, 4] }}
            overlayClassName={`multiselect-dropdown ${overlayClassName}`}
            placement={placement}
            trigger={["click"]}
            overlayStyle={{
              background: "#fff",
              width: dropdownRef.current?.clientWidth,
              height: "400px",
              overflow: "scroll",
            }}
          >
            <div
              style={disabled ? { cursor: "not-allowed" } : {}}
              className={`${styles.wrapper} ${dropdownClassName}`}
            >
              {label && (
                <React.Fragment>
                  {React.isValidElement(label) ? (
                    label
                  ) : (
                    <span className={styles.label}>{label}</span>
                  )}
                </React.Fragment>
              )}
              <div
                ref={dropdownRef}
                className={`${
                  error && error.message
                    ? styles.errorDropdownWrapper
                    : styles.dropdownWrapper
                }`}
              >
                <div className={styles.dropdown}>
                  <span>
                    {selectedOptionLabel
                      ? selectedOptionLabel
                      : value.length > 1 && value.length !== options.length
                      ? `${value.length} selected`
                      : value.length > 1 && value.length === options.length
                      ? allText
                      : placeholder}
                  </span>

                  <img
                    className={
                      isOpen ? styles.dropdownOpening : styles.dropdownClosed
                    }
                    src={images.ic_chevron_down}
                  />
                </div>
                {error && !hideError && (
                  <span className={styles.error}>{error.message}</span>
                )}
              </div>
            </div>
          </Dropdown>
        );
      }}
    />
  );
};

export default React.memo(MultiSelect);
