import React from "react";

export const Blanket: React.FC<JSX.IntrinsicElements["div"]> = (props) => {
  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: "fixed",
        zIndex: 100,
      }}
      {...props}
    />
  );
};

export default Blanket;
