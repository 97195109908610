import React, { useMemo } from "react";
import { CrossIcon, IconPlus } from "../../../../assets/images/images";
import styles from "./styles.module.sass";
import "./styles.less";
import { GenericDropdown } from "../../../../components";
import {
  allOperators,
  lifetimeOfflineOptions,
  notTempUserOption,
  numericOperators,
  offlineAndFloatingUserOptions,
  onlineAppUserOptions,
  operatorAsArray,
  sharedUserOptions,
} from "../../../../constant/customer-segmention-options";
import LogicalOperator from "../LogicalOperator";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CampaignFormValues } from "../../../../types";
import { ISelectItem } from "../../../../components/shared/types";
import { operatorsOptions } from "../../../../helpers";

export const SegmentationFilters: React.FC<{
  index: number;
}> = ({ index }) => {
  const {
    watch,
    control,
    setValue,
    register,
    formState,
    clearErrors,
    getFieldState,
  } = useFormContext<CampaignFormValues>();
  const watchers = watch();
  const { append, fields, remove } = useFieldArray({
    name: `segment.${index}.filter.conditions`,
    control: control,
  });

  const addNewCondition = () => {
    append({ field: "", operator: "equal", value: "" });
  };

  const conditionValueError = (conditionIndex: number) => {
    if (formState.errors && formState.errors.segment) {
      return (formState.errors?.segment[index]?.filter?.conditions || [])[
        conditionIndex
      ]?.value?.message;
    }
  };
  const user_type: string[] = useMemo(() => {
    return watch().segment[index].user_type;
  }, [watch().segment[index].user_type]);

  const allUserTypeSelected = useMemo(
    () =>
      user_type.includes("online_app_user") &&
      user_type.includes("offline_app_user") &&
      user_type.includes("temp_user"),
    [user_type]
  );
  const coditionFieldOptions = useMemo(() => {
    let options: ISelectItem<string>[] = [...sharedUserOptions];
    if (user_type.includes("online_app_user") && !allUserTypeSelected) {
      options = [...options, ...notTempUserOption, ...onlineAppUserOptions];
    } else if (user_type.includes("offline_app_user") && !allUserTypeSelected) {
      options = [
        ...options,
        ...notTempUserOption,
        ...offlineAndFloatingUserOptions,
        ...lifetimeOfflineOptions,
      ];
    } else if (user_type.includes("temp_user") && !allUserTypeSelected) {
      options = [...options, ...lifetimeOfflineOptions];
    } else {
      options = [
        ...options,
        ...notTempUserOption,
        ...onlineAppUserOptions,
        ...offlineAndFloatingUserOptions,
        ...lifetimeOfflineOptions,
      ];
    }
    return options;
  }, [user_type, allUserTypeSelected]);

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Filters</span>
      <div className={styles.filterWrapper}>
        <div className={styles.conditionsWrapper}>
          {watchers.segment[index].filter.conditions.length > 1 && (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <LogicalOperator index={index} />
            </div>
          )}
          {fields.map((_, currentIndex) => (
            <div className={styles.conditionWrapper} key={currentIndex}>
              <div className={styles.conditionInputs}>
                <GenericDropdown
                  name={`segment.${index}.filter.conditions.${currentIndex}.field`}
                  placeholder={"Choose segment..."}
                  options={coditionFieldOptions}
                  dropdownClassName={"field-dropdown"}
                  errorDropdownClassName={"error-field-dropdown"}
                  formState={formState}
                  getFieldState={getFieldState}
                  value={
                    !watchers.segment[index].filter.conditions[
                      currentIndex
                    ].field.trim()
                      ? undefined
                      : watchers.segment[index].filter.conditions[currentIndex]
                          .field
                  }
                  arrowColor={"#000"}
                  className={styles.fieldWrapper}
                  control={control}
                  customOnChange={() => {
                    setValue(
                      `segment.${index}.filter.conditions.${currentIndex}.operator`,
                      "equal"
                    );
                    setValue(
                      `segment.${index}.filter.conditions.${currentIndex}.value`,
                      ""
                    );
                    clearErrors(
                      `segment.${index}.filter.conditions.${currentIndex}.field`
                    );
                  }}
                />
                {watchers.segment[index].filter.conditions[currentIndex]
                  .field && (
                  <GenericDropdown
                    name={`segment.${index}.filter.conditions.${currentIndex}.operator`}
                    options={operatorsOptions(
                      watchers.segment[index].filter.conditions[
                        currentIndex
                      ].field.trim()
                    )}
                    dropdownClassName={"operator-dropdown"}
                    errorDropdownClassName={"error-field-dropdown"}
                    arrowColor={"#000"}
                    className={styles.fieldWrapper}
                    value={watchers.segment[index].filter.conditions[
                      currentIndex
                    ].operator.trim()}
                    control={control}
                    formState={formState}
                    customOnChange={() => {
                      clearErrors(
                        `segment.${index}.filter.conditions.${currentIndex}.value`
                      );
                    }}
                    getFieldState={getFieldState}
                  />
                )}
                {watchers.segment[index].filter.conditions[currentIndex]
                  .operator &&
                  watchers.segment[index].filter.conditions[currentIndex]
                    .field &&
                  (watchers.segment[index].filter.conditions[currentIndex]
                    .operator in operatorAsArray ? (
                    <div className={styles.conditionInputsValuesWrapper}>
                      <textarea
                        {...register(
                          `segment.${index}.filter.conditions.${currentIndex}.value`
                        )}
                        placeholder={"Enter value"}
                        className={styles.conditionValueTextarea}
                        style={
                          conditionValueError(currentIndex)
                            ? { border: "1px solid #EE5253" }
                            : {}
                        }
                      />
                      <span className={styles.conditionValueInputError}>
                        {conditionValueError(currentIndex)}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.conditionInputsValuesWrapper}>
                      <input
                        {...register(
                          `segment.${index}.filter.conditions.${currentIndex}.value`
                        )}
                        placeholder={"Enter value"}
                        className={styles.conditionValueInput}
                        style={
                          conditionValueError(currentIndex)
                            ? { border: "1px solid #EE5253" }
                            : {}
                        }
                        type={
                          watchers.segment[index].filter.conditions[
                            currentIndex
                          ].field in { ...numericOperators, ...allOperators }
                            ? "number"
                            : "text"
                        }
                      />
                      <span className={styles.conditionValueInputError}>
                        {conditionValueError(currentIndex)}
                      </span>
                    </div>
                  ))}
              </div>
              <div
                onClick={() => {
                  remove(currentIndex);
                }}
                className={styles.removeElement}
              >
                <CrossIcon />
              </div>
            </div>
          ))}
          <button
            type={"button"}
            onClick={addNewCondition}
            className={styles.addNewCondition}
          >
            <div className={styles.addNewConditionIcon}>
              <IconPlus />
            </div>
            New condition
          </button>
        </div>
      </div>
    </div>
  );
};

export default SegmentationFilters;
