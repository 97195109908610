import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MultiSelect } from "../../../../components";
import styles from "./styles.module.sass";
import "./styles.less";
import { CampaignFormValues } from "../../../../types";

const UserType: React.FC<{ index: number }> = ({ index }) => {
  const {
    control,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext<CampaignFormValues>();
  const watchers = watch();
  const userTypeOptions = useMemo(
    () => [
      {
        value: "online_app_user",
        label: (
          <span className={styles.sublabel}>
            Ordering users{" "}
            <span style={{ color: "#65656C" }}>— (1,000,000 users)</span>
          </span>
        ),
      },
      {
        value: "offline_app_user",
        label: (
          <span className={styles.sublabel}>
            Offline Users
            <span style={{ color: "#65656C" }}>— (1,000,000 users)</span>
          </span>
        ),
      },
      {
        value: "temp_user",
        label: (
          <span className={styles.sublabel}>
            Temp App User
            <span style={{ color: "#65656C" }}>— (1,000,000 users)</span>
          </span>
        ),
      },
    ],
    []
  );
  return (
    <div className={styles.wrapper}>
      <MultiSelect
        value={watchers.segment[index].user_type}
        onSelect={() => {
          clearErrors(`segment.${index}.user_type`);
        }}
        overlayClassName={"overlayWrapper"}
        dropdownClassName={styles.dropdownWrapper}
        label={"Users type"}
        control={control as any}
        placeholder={"Select users type..."}
        options={userTypeOptions}
        name={`segment.${index}.user_type`}
        hideError={true}
        allText={"All"}
      />
      {errors.segment && errors.segment[index]?.user_type && (
        <span className={styles.userTypeError}>
          {errors.segment[index]?.user_type?.message}
        </span>
      )}
    </div>
  );
};

export default UserType;
