import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.sass";
import images, { ChevronDown } from "../../../../assets/images/images";
import { Dropdown } from "../../../../components";
import { Channel } from "../../CampaignsList/types";

export interface ConnectionOption {
  value: Channel;
  label: string;
  selected: boolean;
}
export const TagSelector: React.FC<{
  setTagFilter: (value: Channel[]) => void;
}> = ({ setTagFilter }) => {
  const TagsOptions: ConnectionOption[] = [
    { value: "SMS", label: "SMS", selected: false },
    { value: "Notification", label: "Notification", selected: false },
    { value: "Whatsapp", label: "Whatsapp", selected: false },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  const [options, setOptions] = useState<ConnectionOption[]>(TagsOptions);

  const allSelected = useMemo(() => {
    return options.filter((item) => item.selected).length === options.length;
  }, [options]);
  const selectedOptions: string = useMemo(() => {
    const selected: string[] = options.reduce(
      (acc: string[], item: ConnectionOption) => {
        return item.selected ? [...acc, item.label] : [...acc];
      },
      []
    );
    return allSelected || selected.length === 0
      ? "All"
      : selected.length === 1
      ? selected[0]
      : `${selected.length} Selected`;
  }, [options]);
  const toggleSelection = (item: ConnectionOption) => {
    const optionsClone = [...options];
    const selectedIndex = optionsClone.findIndex(
      (connection) => connection.value == item.value
    );
    if (selectedIndex > -1) {
      optionsClone[selectedIndex].selected =
        !optionsClone[selectedIndex].selected;
      setOptions([...optionsClone]);
    }
  };
  const toggleAll = () => {
    setOptions([
      ...options.map((item) => ({ ...item, selected: !allSelected })),
    ]);
  };
  useEffect(() => {
    setTagFilter(
      options
        .filter((item) => item.selected)
        .map((item) => item.label) as Channel[]
    );
  }, [options]);
  return (
    <Dropdown
      isOpen={menuOpen}
      onClose={() => {
        setMenuOpen(false);
      }}
      target={
        <div
          className={styles.dropdownBtn}
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <div className={styles.dropdownLabel}>
            Channel tags:{" "}
            <span style={{ fontWeight: "700", fontSize: "14px" }}>
              {selectedOptions}
            </span>
          </div>
          <ChevronDown
            width={20}
            height={20}
            className={menuOpen ? styles.dropdownOpened : ""}
            style={{ marginLeft: "4px" }}
            stroke={"#503E9D"}
          />
        </div>
      }
    >
      <div
        className={styles.dropdownContainer}
        onBlur={() => {
          setMenuOpen(false);
        }}
      >
        <div
          onClick={() => {
            toggleAll();
          }}
          className={styles.dropdownWrapper}
        >
          <span
            className={`${styles.check} ${allSelected ? styles.active : ""}`}
          >
            {allSelected && <img src={images.check} alt="" />}
          </span>
          <span className={styles.itemDropdropLabel}>All</span>
        </div>
        {options.map((element) => (
          <div
            onClick={() => {
              toggleSelection(element);
            }}
            key={element.value}
            className={styles.dropdownWrapper}
          >
            <span
              className={`${styles.check} ${
                element.selected ? styles.active : ""
              }`}
            >
              {element.selected && <img src={images.check} alt="" />}
            </span>
            <span className={styles.itemDropdropLabel}>{element.label}</span>
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default TagSelector;
