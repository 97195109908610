import React, { useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { FormValues } from "./types";
import styles from "./styles.module.sass";
import "./styles.less";
import { useForm } from "react-hook-form";
import {
  checkButtonAppear,
  testcashbackVisibility,
  testDeliveryAndPickup,
  testPromocodeVisibility,
} from "../../constant/TestTargetBrands";
import { TargetBrands } from "./TargetBrands";
import { getPromoHighestRank } from "../../store/thunks";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useSelect } from "../../hooks";
import { initialPromoInfo, setInfo } from "../../store/slices/promoInfo-slice";
import {
  clearSelectedBrands,
  selectAll,
} from "../../store/slices/country-brands-slice";
import { ArrowDown } from "../../assets";
import { yupResolver } from "@hookform/resolvers/yup";
import { promocodeInfoValidationSchema } from "../../helpers/validator/promoCodeValidator";
import {
  PromocodeVisibility,
  PromocodeTypes,
  PromocodeTypesWithCashback,
  DeliveryAndPickup,
  COUNTRIES,
  ADD_BRAND,
} from "../../constant/DropdownOptions";
import { allEq } from "../../helpers";
import { useDeepCompareEffect } from "use-deep-compare";
import {
  PromocodeSetting,
  PromocodeTargetedUsers,
  PromocodeSplit,
} from "../../layouts";
import PromoInfo from "../../layouts/Promocode/PromocodeInfo";
import {
  GenericDropdownOptions,
  BrandSelectionModal,
  SectionWrapper,
  BrandCSVModal,
  CustomDropDown,
  GenericConfimationModal,
} from "../../components";
import { PromotionType } from "../../layouts/Promocode/types";
import { usePromocodeCreationMutation } from "../../store/apis/createPromocode-api";
import { ClipLoader } from "react-spinners";
import { getpromoByRankApi } from "../../store/apis/getpromoByRank-api";
import { socketAuth } from "../../store/thunks/socketAuth";

const Options = ADD_BRAND.map(({ id, brand }) => ({
  key: id.toString(),
  label: brand,
}));
export const Promocode: React.FC = () => {
  // dispatcher
  const dispatch = useAppDispatch();

  // store values
  const {
    brandsReducer: { selectedBrands, brands },
    promoInfoReducer: { info },
    getPromoByRankReducer,
  } = useSelect((state) => {
    return state;
  });
  // form values
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    register,
    setError,
    clearErrors,
    unregister,
    handleSubmit,
    reset,
    setFocus,
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      ...info,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(promocodeInfoValidationSchema()),
  });
  const watchers = watch();

  /** promo rank  */
  const [shiftPromoRank, setShiftPromoRank] = useState(false);
  const { data: promocodeByRankData } =
    getpromoByRankApi.endpoints.getPromoByRank.select({
      country_code: watchers.country_code === "+2" ? "eg" : "sa",
      rank: watchers.rank,
    })({
      getPromoByRankReducer,
    });
  /** promocode validation */

  const [createPromocode, { isSuccess, reset: resetCreatePromocode }] =
    usePromocodeCreationMutation();
  // form values

  // constants
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [selectCountry, setSelectedCountry] = useState<string>(
    info.country_code
  );
  const [openBrandSelectionModal, setOpenBrandSelectionModal] =
    useState<boolean>(false);

  const [openCSVModal, setOpenCSVModal] = useState<boolean>(false);
  const [selectedBrandsOption, setSelectedBrandsOption] = useState<string>("");
  const cashbackVisibility = useMemo(
    () =>
      watchers.visibility_type === "invisible"
        ? PromocodeTypesWithCashback
        : PromocodeTypes,
    [watchers.visibility_type]
  );

  const { Option } = Select;
  const selectedBrandsCount = useMemo(() => {
    return brands.filter((item) => item.selected).length;
  }, [brands]);

  const setFlagKeyValue = (
    key: keyof FormValues,
    condition: boolean,
    split: boolean
  ) => {
    if (condition) {
      setValue(key, split);
      dispatch(
        setInfo({
          [key]: split,
        })
      );
    }
  };
  // constants
  useDeepCompareEffect(() => {
    const {
      split_flag,
      min_eligible_invoice_flag,
      max_discount_values_flag,
      expires_after_values_flag,
      expires_after_values,
      min_eligible_invoices,
      brand_percentages,
      koinz_percentages,
      max_discount_values,
    } = watchers;
    const values = [...watchers.brands];
    const newBrands = brands
      .filter((brand) => brand.selected)
      .map(({ name, id }) => {
        const isInForm = values.find((value) => {
          return value.id === id;
        });

        return isInForm
          ? isInForm
          : {
              name,
              id,
              koinz_percentage: split_flag
                ? koinz_percentages
                : watchers.visibility_type === "invisible"
                ? 100
                : 0,
              brand_percentage: split_flag
                ? brand_percentages
                : watchers.visibility_type === "invisible"
                ? 0
                : 100,
              min_eligible_invoice: min_eligible_invoice_flag
                ? min_eligible_invoices
                : 0,
              max_discount_value: max_discount_values_flag
                ? max_discount_values
                : 0,
              country_iso_code: watchers.country_code,
              expires_after_value: expires_after_values_flag
                ? expires_after_values ?? 72
                : 72,
            };
      });

    const allBrandPercentageEq = allEq("brand_percentage", newBrands);
    const allMinInvoiceEq = allEq("min_eligible_invoice", newBrands);
    const allMaxDiscountEq = allEq("max_discount_value", newBrands);
    const allExpiresAfterEq = allEq("expires_after_value", newBrands);

    setFlagKeyValue(
      "split_flag",
      watchers.split_flag && !allBrandPercentageEq,
      false
    );
    setFlagKeyValue(
      "min_eligible_invoice_flag",
      watchers.min_eligible_invoice_flag && !allMinInvoiceEq,
      false
    );
    setFlagKeyValue(
      "max_discount_values_flag",
      watchers.max_discount_values_flag && !allMaxDiscountEq,
      false
    );
    setFlagKeyValue(
      "expires_after_values_flag",
      watchers.expires_after_values_flag && !allExpiresAfterEq,
      false
    );
    dispatch(
      setInfo({
        country_code: watchers.country_code,
        brands: [...newBrands],
      })
    );
    setValue("brands", [...newBrands]);
  }, [brands]);

  // funcions
  const handleChange = (value: string) => {
    setSelectedCountry(value);
    checkButtonAppear(value);
    if (!selectedBrandsCount) {
      setValue("country_code", value);
      dispatch(setInfo({ country_code: value }));
    } else if (selectedBrandsCount) {
      setOpenCancelModal(true);
    }
    setSelectedCountry(value);
    checkButtonAppear(value);
  };

  const handlePromocodeInfoSubmit = (values: FormValues) => {
    if (promocodeByRankData.promo_code) {
      setShiftPromoRank(true);
    } else {
      setShiftPromoRank(false);
      createPromocode({ data: values });
    }
  };

  const closeCSVModal = () => {
    setOpenBrandSelectionModal(false);
    setOpenCSVModal(false);
  };
  const handleOpenModal = (key: string) => {
    if (selectedBrandsOption !== key && selectedBrandsCount) {
      setOpenCancelModal(true);
    } else {
      if (key === ADD_BRAND[0].id) {
        setOpenBrandSelectionModal(true);
      } else {
        setOpenCSVModal(true);
      }
      setSelectedBrandsOption(key);
    }
  };
  const prevOption = useMemo(
    () =>
      selectedBrandsOption === ADD_BRAND[0].id
        ? ADD_BRAND[1].id
        : ADD_BRAND[0].id,
    [selectedBrands]
  );
  // functions

  // useEffects hooks
  // get promo highest rank

  useEffect(() => {
    dispatch(getPromoHighestRank({}));
  }, []);
  // get promo highest rank
  useEffect(() => {
    if (watchers.visibility_type === "visible") {
      dispatch(setInfo({ visibility_type: "visible" }));
      setValue("koinz_percentages", 0);
      setValue("brand_percentages", 100);
    }
    if (watchers.visibility_type === "invisible") {
      dispatch(setInfo({ visibility_type: "invisible" }));
      setValue("koinz_percentages", 100);
      setValue("brand_percentages", 0);
    }
  }, [watchers.visibility_type]);

  // reset form on deleting selected brands
  useEffect(() => {
    setSelectedBrandsOption("");
  }, [watchers.country_code]);

  useEffect(() => {
    if (!selectedBrands.length) {
      dispatch(
        setInfo({ ...initialPromoInfo, country_code: watchers.country_code })
      );
      reset({ ...initialPromoInfo, country_code: watchers.country_code });
    }
  }, [selectedBrands]);

  useEffect(() => {
    if (!selectedBrands.length) {
      dispatch(
        setInfo({ ...initialPromoInfo, country_code: watchers.country_code })
      );
    }
  }, [selectedBrands]);

  useEffect(() => {
    testPromocodeVisibility(PromocodeVisibility);
    testcashbackVisibility(cashbackVisibility);
    testDeliveryAndPickup(DeliveryAndPickup);
  }, [PromocodeVisibility, cashbackVisibility, DeliveryAndPickup]);

  useEffect(() => {
    if (isSuccess) {
      resetCreatePromocode();
    }
  }, [isSuccess]);
  //actions on changing promocode visibility
  // useEffect hooks
  useEffect(() => {
    dispatch(
      socketAuth({ channel_name: "presence-customer-file-verification" })
    );
  }, []);
  return isSuccess ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <ClipLoader color="#feb114" />
    </div>
  ) : (
    <div>
      <div className={styles.addPromoTitleContainer}>
        <h1 className={styles.addPromoTitle}>Add Promocode</h1>
      </div>
      <div>
        <form onSubmit={handleSubmit(handlePromocodeInfoSubmit)}>
          <SectionWrapper
            title="Target Brands"
            wrapperClassName={styles.sectionContainer}
          >
            <div className={styles.wrapper}>
              <h3 className={styles.label}>Country</h3>
              <div className={styles.container}>
                <div className={styles.dropdownContainer}>
                  <CustomDropDown
                    onChange={handleChange}
                    placeholder="Select Country..."
                    size="large"
                    suffixIcon={<ArrowDown stroke={"#1C1C1C"} />}
                    className="arrowStyle select-country "
                    value={
                      watchers.country_code
                        ? { value: watchers.country_code }
                        : undefined
                    }
                  >
                    {COUNTRIES.map((item) => (
                      <Option key={item.id} value={item.countryCode}>
                        <div className={styles.dropdownOptionsContainer}>
                          <div>
                            <img
                              src={item.image}
                              key={item.id}
                              alt={`${item.countryName}-${item.id}`}
                              className={styles.flag}
                            />
                          </div>
                          <div>
                            <h3 className={styles.countryName}>
                              {item.countryName}
                            </h3>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </CustomDropDown>
                  <h5 className={styles.dropdownLabel}>
                    Select country to add brands
                  </h5>
                </div>
                <div className={styles.brandContainer}>
                  {selectCountry && (
                    <>
                      <GenericDropdownOptions
                        selectedKey={selectedBrandsOption}
                        className="genericContainer"
                        onClick={(value) => {
                          handleOpenModal(value.key);
                        }}
                        items={Options}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <TargetBrands />
          </SectionWrapper>

          {openBrandSelectionModal && (
            <BrandSelectionModal
              setValue={setValue}
              watchers={watchers}
              isOpen={openBrandSelectionModal}
              onClose={() => setOpenBrandSelectionModal(false)}
              country_code={watchers.country_code}
              prevOption={prevOption}
            />
          )}
          <BrandCSVModal openModal={openCSVModal} onClose={closeCSVModal} />
          {selectedBrands.length > 0 && (
            <div>
              <SectionWrapper
                title="Promocode name & title"
                wrapperClassName={styles.sectionContainer}
              >
                <PromoInfo
                  setShiftPromoRank={setShiftPromoRank}
                  shiftPromoRank={shiftPromoRank}
                  watchers={watchers}
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                />
              </SectionWrapper>

              <SectionWrapper
                title="Promocode settings"
                wrapperClassName={styles.sectionContainer}
              >
                <PromocodeSetting
                  watchers={watchers}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                />
              </SectionWrapper>
              {watchers.promotion_type !== PromotionType.INITIAL_TYPE_VALUE && (
                <>
                  <SectionWrapper
                    title="Promocode Split"
                    wrapperClassName={styles.sectionContainer}
                  >
                    <PromocodeSplit
                      watchers={watchers}
                      errors={errors}
                      register={register}
                      control={control}
                      setValue={setValue}
                      setFocus={setFocus}
                      reset={reset}
                      trigger={trigger}
                    />
                  </SectionWrapper>

                  <PromocodeTargetedUsers
                    unregister={unregister}
                    watchers={watchers}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                  />
                  <SectionWrapper wrapperClassName={styles.sectionContainer}>
                    <button
                      className={`${styles.btn} ${styles["btn-primary"]}`}
                      type="submit"
                    >
                      Add Promocode
                    </button>
                  </SectionWrapper>
                </>
              )}
            </div>
          )}
        </form>
      </div>

      {openCancelModal && (
        <GenericConfimationModal
          isOpen={openCancelModal}
          onClose={() => {
            setOpenCancelModal(false);
          }}
          applyAction={() => {
            setSelectedBrandsOption(
              selectedBrandsOption === ADD_BRAND[0].id
                ? ADD_BRAND[1].id
                : ADD_BRAND[0].id
            );
            dispatch(selectAll({ isAllSelected: false }));
            dispatch(clearSelectedBrands());
            setValue("country_code", selectCountry);
            dispatch(setInfo({ country_code: selectCountry }));
            setOpenCancelModal(false);
            if (selectedBrandsOption === ADD_BRAND[0].id) {
              setOpenCSVModal(true);
            } else {
              setOpenBrandSelectionModal(true);
            }
          }}
          alertType="warn"
          buttonContent={"Discard"}
          description={`Are you sure you want to discard adding ${selectedBrandsCount} brands to this promocode?`}
          title={`Discard adding ${selectedBrandsCount} brands`}
        />
      )}
    </div>
  );
};

export default Promocode;
