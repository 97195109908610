import React from "react";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "./App.less";
import { AppRoutes } from "./routes";
import styles from "./styles.module.sass";
import { ConnectionDetector } from "./components";
import { Toaster } from "react-hot-toast";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Toaster />
        <ConnectionDetector />
        <div className={styles.App}>
          <AppRoutes />
        </div>
      </PersistGate>
    </Provider>
  );
};

export default App;
