import React, { useMemo, useState } from "react";
import styles from "./styles.module.sass";
import images, { ChevronDown } from "../../../../assets/images/images";
import { Dropdown } from "../../../../components";
import { GenericMultiSelectorProps, GenericOption } from "./types";

export const GenericMultiSelector: React.FC<GenericMultiSelectorProps> = ({
  options,
  updateOptions,
  label,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const allSelected = useMemo(() => {
    return options.filter((item) => item.selected).length === options.length;
  }, [options]);
  const selectedOptions: string = useMemo(() => {
    const selected: string[] = options.reduce(
      (acc: string[], item: GenericOption) => {
        return item.selected ? [...acc, item.label] : [...acc];
      },
      []
    );
    return allSelected || selected.length === 0
      ? "All"
      : selected.length === 1
      ? selected[0]
      : `${selected.length} Selected`;
  }, [options, allSelected]);
  const toggleSelection = (item: GenericOption) => {
    const optionsClone = [...options];
    const selectedIndex = optionsClone.findIndex(
      (option) => option.value == item.value
    );
    if (selectedIndex > -1) {
      optionsClone[selectedIndex].selected =
        !optionsClone[selectedIndex].selected;
      updateOptions([...optionsClone]);
    }
  };
  const toggleAll = () => {
    updateOptions([
      ...options.map((item) => ({ ...item, selected: !allSelected })),
    ]);
  };

  return (
    <Dropdown
      isOpen={menuOpen}
      onClose={() => {
        setMenuOpen(false);
      }}
      target={
        <div
          className={styles.dropdownBtn}
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <div className={styles.dropdownLabel}>
            {label}:<span className={styles.title}>{selectedOptions}</span>
          </div>
          <ChevronDown
            width={20}
            height={20}
            className={menuOpen ? styles.dropdownOpened : ""}
            style={{ marginLeft: "4px" }}
            stroke={"#503E9D"}
          />
        </div>
      }
    >
      <div
        className={styles.dropdownContainer}
        onBlur={() => {
          setMenuOpen(false);
        }}
      >
        <div
          onClick={() => {
            toggleAll();
          }}
          className={styles.dropdownWrapper}
        >
          <span
            className={`${styles.check} ${allSelected ? styles.active : ""}`}
          >
            {allSelected && <img src={images.check} alt="" />}
          </span>
          <span className={styles.itemDropdropLabel}>All</span>
        </div>
        {options.map((element) => (
          <div
            onClick={() => {
              toggleSelection(element);
            }}
            key={element.value}
            className={styles.dropdownWrapper}
          >
            <span
              className={`${styles.check} ${
                element.selected ? styles.active : ""
              }`}
            >
              {element.selected && <img src={images.check} alt="" />}
            </span>
            <span className={styles.itemDropdropLabel}>{element.label}</span>
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default GenericMultiSelector;
