import { TimePicker } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { MultiSelect } from "../../../../components";
import styles from "./styles.module.sass";
import "./styles.less";
import moment from "moment";
import { getWeeksInAMonth } from "../../../../helpers";
import { monthOptions, useTimeTrigger } from "../../../../hooks";
import { daysOptions } from "./options";

const TriggerSettings: React.FC<{ index: number }> = ({ index }) => {
  const { control, watchers, setValue, weeksOptions, weekdaysOptions } =
    useTimeTrigger(index);
  return (
    <div key={index} className={styles.wrapper}>
      <MultiSelect
        value={watchers.time_triggers[index].month}
        name={`time_triggers.${index}.month`}
        control={control}
        options={monthOptions}
        placeholder={"Select month..."}
        allText={"Any Month"}
        label={"Month:"}
        onSelect={(item) => {
          if (!watchers.time_triggers[index].month.includes(item.value)) {
            setValue(
              `time_triggers.${index}.week`,
              watchers.time_triggers[index].week.filter((element: number) =>
                getWeeksInAMonth({
                  months: watchers.time_triggers[index].month,
                }).includes(element)
              )
            );
          }
        }}
      />
      <div className={styles.separator} />
      <MultiSelect
        value={watchers.time_triggers[index].week}
        name={`time_triggers.${index}.week`}
        control={control}
        disabled={!watchers.time_triggers[index].month?.length}
        options={weeksOptions}
        placeholder={"Select week..."}
        allText={"Any Week"}
        label={"Week:"}
      />
      <div className={styles.separator} />
      <MultiSelect
        value={watchers.time_triggers[index].week_day}
        disabled={
          !watchers.time_triggers[index].week?.length ||
          !watchers.time_triggers[index].month?.length
        }
        control={control}
        options={weekdaysOptions.length > 0 ? weekdaysOptions : daysOptions}
        placeholder={"Select day..."}
        name={`time_triggers.${index}.week_day`}
        allText={"Any Day"}
        overlayClassName={"dropdown-for-select-day"}
        label={"Day:"}
      />
      <div className={styles.separator} />
      <Controller
        control={control}
        name={`time_triggers.${index}.time`}
        render={({ field: { onChange } }) => {
          return (
            <div className={styles.messageOnWrapper}>
              <span className={styles.messageOnTitle}>Message on:</span>
              <TimePicker
                use12Hours
                placeholder="12:00 PM"
                clearIcon={false}
                onChange={(value) => {
                  onChange(Number(moment(value).format("x")));
                }}
                value={moment(Number(watchers.time_triggers[index].time))}
                className="message-on-time-picker"
                format="h:mm A"
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default React.memo(TriggerSettings);
